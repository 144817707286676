import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-slide-demo',
  templateUrl: './slide-demo.component.html',
  styleUrls: ['./slide-demo.component.scss'],
})
export class SlideDemoComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
