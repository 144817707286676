import { getPlatform, NgModule } from '@angular/core';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { Platform } from '@ionic/angular';
import * as b from "bowser";

const browser = b.getParser(window.navigator.userAgent);
const browserName = browser.getBrowserName();
  console.log(browserName)
  const typeDesk=['desktop','ipad','tablet'];
const routes: Routes = [
  {
    path: '',
    loadChildren: () => {
//console.log(Platform.prototype.platforms())
     if(typeDesk.some(i => Platform.prototype.platforms().includes(i))){
      return import('./homeweb/home.module').then( m => m.HomePageModule)
     }else{

     
      return import('./home/home.module').then( m => m.HomePageModule)
     }
    }
      
  },
  {
    path: 'app',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  },

  
  {
    path: 'modal-edit-orden',
    loadChildren: () => import('./modal-edit-orden/modal-edit-orden.module').then( m => m.ModalEditOrdenPageModule)
  },
  {
    path: 'pagina',
    loadChildren: () => import('./pagina/pagina.module').then( m => m.PaginaPageModule)
  },
  {
    path: 'canales',
    loadChildren: () => import('./canales/canales.module').then( m => m.CanalesPageModule)
  },
  {
    path: 'cortosanimados',
    loadChildren: () => import('./cortosanimados/cortosanimados.module').then( m => m.CortosanimadosPageModule)
  },
  {
    path: 'animationmakers',
    loadChildren: () => import('./animationmakers/animationmakers.module').then( m => m.AnimationmakersPageModule)
  },
  {
    path: 'contacto',
    loadChildren: () => import('./contacto/contacto.module').then( m => m.ContactoPageModule)
  },
  {
    path: 'soporte',
    loadChildren: () => import('./soporte/soporte.module').then( m => m.SoportePageModule)
  },
  {
    path: 'terminosycondiciones',
    loadChildren: () => import('./terminosycondiciones2/terminosycondiciones2.module').then( m => m.Terminosycondiciones2PageModule)
  },
  {
    path: 'suscribete',
    loadChildren: () => import('./info-registro1/info-registro1.module').then( m => m.InfoRegistro1PageModule)
  },
  {
    path: 'dash',
    loadChildren: () => import('./client/dash/dash.module').then( m => m.DashPageModule)
  },
  {
    path: 'aviso-de-privacidad',
    loadChildren: () => import('./avisodeprivacidad/terminosycondiciones2.module').then( m => m.Terminosycondiciones2PageModule)
  },
  {
    path: 'welcome',
    loadChildren: () => import('./welcome/welcome.module').then( m => m.WelcomePageModule)
  },
  
  {
    path: 'metricas',
    loadChildren: () => import('./metricas/metricas.module').then( m => m.MetricasPageModule)
  },
  {
    path: 'presentacion',
    loadChildren: () => import('./presentacion/presentacion.module').then( m => m.PresentacionPageModule)
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then( m => m.AuthPageModule)
  },
  {
    path: 'dash',
    loadChildren: () => import('./client/dash/dash.module').then( m => m.DashPageModule)
  },
  {
    path: 'aa',
    loadChildren: () => import('./test/tailwind/tailwind.module').then( m => m.TailwindPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'app/dash/data/videos/editvideo',
    loadChildren: () => import('./dash/videoseccion/editvideo/editvideo.module').then( m => m.EditvideoPageModule)
  }
  ,
  {
    path: 'app/dash/data/videos/createvideo',
    loadChildren: () => import('./dash/videoseccion/createvideo/createvideo.module').then( m => m.CreatevideoPageModule)
  }

];

@NgModule({ 
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
