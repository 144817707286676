import { Component, OnInit ,Input,OnChanges  } from '@angular/core';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
})
export class AlertComponent implements OnInit {
  error={
    status:true,
    msg:'Suscripcion fallida - cargo no realizado, favor de verificar el saldo de su tarjeta'
  }
  
  @Input() data:any;
  
  
  ngOnChanges() {
    /**********THIS FUNCTION WILL TRIGGER WHEN PARENT COMPONENT UPDATES 'someInput'**************/
    //Write your code here
    console.log('cambio');
    console.log(this.data);
    this.error=this.data;
     
    }   
  constructor() { }

  ngOnInit() {}

}
