import { FotetrComponent } from './fotetr/fotetr.component';
import { AlertComponent } from './alert/alert.component';
import { SlideDemoComponent } from './slide-demo/slide-demo.component';

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';


@NgModule({
  declarations: [SlideDemoComponent,AlertComponent,FotetrComponent],
  imports: [
    CommonModule,

    

  ],
  
  exports:[SlideDemoComponent,AlertComponent,FotetrComponent],
  entryComponents:[SlideDemoComponent,AlertComponent,FotetrComponent]
})
export class ComponentsModule { }
