import { Component, OnInit,HostListener ,Renderer2  } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import {fromEvent, Subject} from 'rxjs';

import { Platform } from '@ionic/angular';
   
@Component({
  selector: 'app-fotetr',
  templateUrl: './fotetr.component.html',
  styleUrls: ['./fotetr.component.scss'],
})

export class FotetrComponent implements OnInit {

  constructor(private routerT: Router,
    private renderer: Renderer2) { }
  display:any ='block';
  display2:any ='none';
  typeDesk=['desktop','ipad','tablet'];
  platform_p=false;

  ngOnInit() {
    this.platform_p= this.typeDesk.some(i => Platform.prototype.platforms().includes(i))
    this.display='block';
    console.log(this.routerT.url)
if(this.routerT.url=='/suscribete'||this.routerT.url=='/dash'||this.routerT.url=='/welcome'||this.routerT.url=='/login'){
  console.log(this.routerT.url)
    this.display='none';
}else{

  var e= document.getElementsByClassName('content_page')[0]; 
if(!this.platform_p){
  this.renderer.listen(e, 'scroll', (event) => {
    // Do something with 'event'
    //console.log(e.scrollTop);
    if(e.scrollTop>10){
      this.display2='block;'
    }
    if(e.scrollTop<10){
      this.display2='none;'
    }
  });
}
 
}

  }
  getYPosition(e: Event): number {
    return (e.target as Element).scrollTop;
  }
  routeTo(type) {
   
        this.routerT.navigateByUrl('/'+type)
    
    
  }
  suscribete(){
    this.routerT.navigateByUrl('/suscribete')
  }
  login(){
    this.routerT.navigateByUrl('/login')
  }
}
